import React from 'react';
import { Box, BoxProps, Flex, P, Span, styled } from 'sp-ui';
import { ReactComponent as BankImage } from '../../assets/svg/bank.svg';
import { Button } from '../form';
import { IBankAccount } from './types';

export const AutomaticVerificationCancelButton = styled(Button)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        color: ${theme.colors.blue[400]};
        margin-top: 20px;
        padding: 8px 70px;
        
        &:hover {
            border-color: ${theme.colors.gray[300]};
            color: ${theme.colors.blue[700]};
        }
    `
);

interface IBankAccountProps {
    bankAccount: IBankAccount;
}

const BankAccount: React.FC<IBankAccountProps> = ({ bankAccount }) => {
    const { accountName, accountNumberMask, routingNumber } = bankAccount;

    return (
        <BankAccountBox>
            <BankAccountImage accountNumberMask={accountNumberMask} routingNumber={routingNumber} />
            {accountName && (
                <BankAccountDetails
                    accountName={accountName}
                    accountNumberMask={accountNumberMask}
                />
            )}
        </BankAccountBox>
    );
};

export const BankAccountBox = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        padding: 16px;

        p {
            line-height: 24px;
        }

        ${theme.responsive.smallScreenOnly`
            img {
                margin-right: 16px;
            }
        `}
    `
);

interface IBankAccountDetailsProps {
    accountName: string;
    accountNumberMask: string;
}

export const BankAccountDetails: React.FC<IBankAccountDetailsProps> = ({
    accountName,
    accountNumberMask
}) => (
    <Box flex="1">
        <P>{accountName}</P>
        <Span>{`•••• ${accountNumberMask}`}</Span>
    </Box>
);

interface IBankAccountImageProps {
    accountNumberMask?: string;
    routingNumber?: string;
}

export const BankAccountImage: React.FC<BoxProps & IBankAccountImageProps> = ({
    accountNumberMask,
    routingNumber,
    ...props
}) => {
    return (
        <>
            <Box marginRight="20px" width="60px" {...props}>
                <BankImage />
            </Box>
            {accountNumberMask && routingNumber && (
                <BankAccountDetails accountName="" accountNumberMask={accountNumberMask} />
            )}
        </>
    );
};

export const BankAccountNeedsVerificationBox = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isHidden'
})(
    ({ isHidden, theme }) => `
        align-items: center;
        border: 1px solid ${theme.colors.gray[200]};
        padding: 30px 20px 24px;

        ${isHidden ? 'display: none;' : ''};

        p {
            color: ${theme.colors.gray[600]};
            flex: 1;
            line-height: 24px;
        }

        svg {
            flex: 0 0 100px;
            height: 100px;
            margin-right: 12px;
        }

        ${theme.responsive.smallScreenOnly`
            flex-direction: column;
            padding: 16px;

            button {
                width: 100%;
            }

            p {
                text-align: center;
            }

            svg {
                margin-bottom: 22px;
                margin-right: 0;
            }
        `}
    `
);

export default BankAccount;
