import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { IPayoutAccount } from './types';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';

export interface IPayoutAccountContext {
    addToPayoutAccounts: (payoutAccount: IPayoutAccount) => void;
    getPayoutAccounts: () => void;
    payoutAccounts: IPayoutAccount[];
    payoutAccountsLoading: boolean;
    removeFromPayoutAccounts: (payoutAccountId: string) => void;
    setPayoutAccounts: (payoutAccounts: IPayoutAccount[]) => void;
}

const PayoutAccountContext = createContext<IPayoutAccountContext>({
    addToPayoutAccounts: () => {},
    getPayoutAccounts: () => {},
    payoutAccounts: [],
    payoutAccountsLoading: false,
    removeFromPayoutAccounts: () => {},
    setPayoutAccounts: () => {}
});

export const PayoutAccountProvider: React.FC = ({ children, ...props }) => {
    const addToPayoutAccounts = (payoutAccount) => {
        const { isDefaultForCurrency } = payoutAccount;

        setPayoutAccounts([
            ...payoutAccounts.map((payoutAccount) => {
                if (isDefaultForCurrency) {
                    payoutAccount.isDefaultForCurrency = false;
                }

                return payoutAccount;
            }),
            payoutAccount
        ]);
    };
    const [payoutAccounts, setPayoutAccounts] = useState<IPayoutAccount[]>([]);
    const payoutAccountPath = usePaymentsApiMerchantAccountPath('/payout-account');
    const [payoutAccountsLoading, setPayoutAccountsLoading] = useState<boolean>(true);
    const [payoutAccountsResponse, loading, getPayoutAccounts] = usePaymentsApiFetch(
        payoutAccountPath
    );
    const removeFromPayoutAccounts = useCallback(
        (payoutAccountId) => {
            setPayoutAccounts(payoutAccounts.filter(({ id }) => id !== payoutAccountId));
        },
        [payoutAccounts]
    );

    useEffect(() => {
        getPayoutAccounts();
    }, [getPayoutAccounts]);

    useEffect(() => {
        if (!loading) {
            const { data: payoutAccounts } = payoutAccountsResponse;

            setPayoutAccounts(payoutAccounts);
            setPayoutAccountsLoading(false);
        } else {
            setPayoutAccountsLoading(true);
        }
    }, [loading, payoutAccountsResponse]);

    return (
        <PayoutAccountContext.Provider
            value={{
                addToPayoutAccounts,
                getPayoutAccounts,
                payoutAccounts,
                payoutAccountsLoading,
                removeFromPayoutAccounts,
                setPayoutAccounts
            }}
            {...props}>
            {children}
        </PayoutAccountContext.Provider>
    );
};

export function usePayoutAccount(): IPayoutAccountContext {
    const context = useContext(PayoutAccountContext);

    return context;
}
