import React, { useEffect, useState } from 'react';
import { Box, H1, H2, InfoActionBox, LoadingDialog } from 'sp-ui';
import EmptyState from '../components/EmptyState';
import { Message, useMessage } from '../components/intl';
import { ReactComponent as DocumentsEmptyStateImage } from '../assets/svg/documents-empty-state-image.svg';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../hooks';

type ITaxDocument = {
    documentType: '1099-K';
    createdDate: number;
    id: string;
    reportingYear: number;
};

interface IDownloadButton {
    taxDocument: ITaxDocument;
}

const DownloadButton: React.FC<IDownloadButton> = ({ taxDocument }) => {
    const taxDocumentPath = usePaymentsApiMerchantAccountPath(`/tax-document/${taxDocument.id}`);

    const { 2: fetchTaxFile } = usePaymentsApiFetch(taxDocumentPath, {
        defer: true
    });

    const getAndDownloadTaxFile = async () => {
        const taxDocumentFileResponse = await fetchTaxFile();

        const fileBlob = await taxDocumentFileResponse.response.blob();

        const url = URL.createObjectURL(fileBlob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `${taxDocument.id}.pdf`;
        a.click();
    };

    return (
        <Box key={taxDocument.id} padding="8px 0 0 0">
            <InfoActionBox
                action={{
                    text: 'Download',
                    click: getAndDownloadTaxFile
                }}
                infoBoxTranslations={{
                    headerText: `${taxDocument.documentType}`,
                    subText: `Created ${new Date(taxDocument.createdDate * 1000).toDateString()}`
                }}
            />
        </Box>
    );
};

const DocumentsPage: React.FC = () => {
    const [taxDocuments, setTaxDocuments] = useState<ITaxDocument[]>([]);
    const taxDocumentsPath = usePaymentsApiMerchantAccountPath('/tax-document');
    const [taxDocumentsResponse, taxDocumentsLoading] = usePaymentsApiFetch(taxDocumentsPath);

    const t = useMessage();

    useEffect(() => {
        if (taxDocumentsResponse?.data) {
            setTaxDocuments(taxDocumentsResponse.data);
        }
    }, [taxDocumentsResponse]);

    const documentsEmptyStateImage = (
        <Box maxWidth="398px" width="398px">
            <DocumentsEmptyStateImage />
        </Box>
    );

    return (
        <>
            <Box padding="0 40px 40px 40px">
                <H1 marginBottom="8px" marginTop="40px">
                    {t('documents.heading')}
                </H1>
            </Box>
            {taxDocumentsLoading ? (
                <LoadingDialog description="" />
            ) : taxDocuments.length === 0 ? (
                <EmptyState
                    description={<Message id="documentsEmptyState.description" />}
                    heading="documentsEmptyState.heading"
                    image={documentsEmptyStateImage}
                />
            ) : (
                <Box padding="0 200px 40px 200px">
                    {taxDocuments
                        .map((taxDocument) => taxDocument.reportingYear)
                        .filter((year, index, self) => self.indexOf(year) === index)
                        .sort((a, b) => b - a)
                        .map((year) => (
                            <Box key={year} padding="25px 0 0 0">
                                <H2>{year}</H2>

                                {taxDocuments
                                    .filter((taxDocument) => taxDocument.reportingYear === year)
                                    .map((taxDocument) => (
                                        <DownloadButton
                                            key={taxDocument.id}
                                            taxDocument={taxDocument}
                                        />
                                    ))}
                            </Box>
                        ))}
                </Box>
            )}
        </>
    );
};

export default DocumentsPage;
