import React, { useCallback } from 'react';
import { Box, CulDeSac, Formik, FormikForm, IModal, styled, yup } from 'sp-ui';
import {
    NewBankAccountModalContent,
    NewDebitCardModalContent,
    PayoutAccountType,
    PayoutAccountTypeRadioGroup,
    usePayoutAccount
} from '../payout-account';
import { Message, useMessage } from '../intl';
import { useToast } from '../toast';

interface INewPayoutAccountModalProps {
    close: () => void;
    modalComponent: IModal;
}

const NewPayoutAccountModal: React.FC<INewPayoutAccountModalProps> = ({
    close,
    modalComponent: Modal
}) => {
    const { createToast } = useToast();
    const { addToPayoutAccounts } = usePayoutAccount();
    const initialValues = { type: '' };
    const t = useMessage();
    const onDebitCardCreated = useCallback(
        (payoutAccount) => {
            addToPayoutAccounts(payoutAccount);
            close();
            createToast({
                text: t('payoutAccount.newPayoutAccountModalToast.text'),
                subText: t('payoutAccount.newPayoutAccountModalToast.subText')
            });
        },
        [addToPayoutAccounts, close, createToast, t]
    );
    const validationSchema = yup.object().shape({
        type: yup.string().oneOf(Object.values(PayoutAccountType)).required()
    });

    return (
        <NewPayoutAccountModalContainer>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnMount
                validationSchema={validationSchema}>
                {({ isValid, values }) => (
                    <FormikForm>
                        <Modal heading={t('settings.newPayoutAccountModal.heading')}>
                            <CulDeSac
                                component={(exit) => (
                                    <NewBankAccountModalContent
                                        onCancel={exit}
                                        onNewBankAccount={() => {
                                            close();
                                        }}
                                    />
                                )}>
                                {(enterNewBankAccountModalContent) => (
                                    <CulDeSac
                                        component={(exit) => (
                                            <NewDebitCardModalContent
                                                onCancel={exit}
                                                onSuccess={onDebitCardCreated}
                                            />
                                        )}>
                                        {(enterNewDebitCardModalContent) => (
                                            <>
                                                <Modal.Body>
                                                    <PayoutAccountTypeRadioGroup
                                                        alwaysSmallScreen
                                                        omitRecommended
                                                    />
                                                </Modal.Body>
                                                <Modal.PrimaryButton
                                                    isDisabled={!isValid}
                                                    onClick={() => {
                                                        const { type } = values || {};

                                                        if (
                                                            type === PayoutAccountType.BankAccount
                                                        ) {
                                                            enterNewBankAccountModalContent();
                                                        } else if (
                                                            type === PayoutAccountType.DebitCard
                                                        ) {
                                                            enterNewDebitCardModalContent();
                                                        }
                                                    }}>
                                                    <Message id="continueButton" />
                                                </Modal.PrimaryButton>
                                            </>
                                        )}
                                    </CulDeSac>
                                )}
                            </CulDeSac>
                        </Modal>
                    </FormikForm>
                )}
            </Formik>
        </NewPayoutAccountModalContainer>
    );
};

const NewPayoutAccountModalContainer = styled(Box)`
    > button {
        display: none;
    }
`;

export default NewPayoutAccountModal;
