import { parse } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../hooks';
import { convertDateToISOStringWithoutMs, getDateAtEndOfDay } from '../date';

export interface IWithPaymentsApiListProps {
    getList: () => void;
    initialCreatedDateRange?: [Date | null, Date | null];
    initialSearch: string;
    isZeroState: boolean;
    list: object[];
    loading: boolean;
    setCreatedDateRange: (dateRange?: [Date | null, Date | null]) => Promise<void>;
    setFilter: (filterParams: [string, string][]) => Promise<void>;
    setPageIndex: (pageIndex: number) => Promise<void>;
    setSearch: (search: string) => Promise<void>;
    setSort: (sort: IWithPaymentsApiListSort) => Promise<void>;
    pageCount: number;
}

interface IWithPaymentsApiListSort {
    by: string;
    direction: 'asc' | 'desc';
}

const withPaymentsApiList: (
    config: {
        initialCreatedDateRange?: [Date | null, Date | null];
        listPath: string;
    },
    Component: React.FC<IWithPaymentsApiListProps>
) => React.FC = ({ listPath, initialCreatedDateRange }, Component) => () => {
    const [{ by, direction }, setSortData] = useState<IWithPaymentsApiListSort>({
        by: 'created',
        direction: 'desc'
    });
    const [initialCreatedAfterDate = '', initialCreatedBeforeDate = ''] =
        initialCreatedDateRange || [];
    const [createdAfter, setCreatedAfter] = useState<string>(
        initialCreatedAfterDate ? convertDateToISOStringWithoutMs(initialCreatedAfterDate) : ''
    );
    const [createdBefore, setCreatedBefore] = useState<string>(
        initialCreatedBeforeDate
            ? convertDateToISOStringWithoutMs(getDateAtEndOfDay(initialCreatedBeforeDate))
            : ''
    );
    const [filterParams, setFilterParams] = useState<[string, string][]>([]);
    const { search } = useLocation();
    const initialSearch = `${parse(search).search || ''}`;
    const [isZeroState, setIsZeroState] = useState<boolean>(false);
    const [{ limit, offset }, setPagination] = useState<{
        limit: number;
        offset: number;
    }>({
        limit: 25,
        offset: 0
    });
    const paymentsListApiPath = usePaymentsApiMerchantAccountPath(listPath);
    const [searchQuery, setSearchQuery] = useState<string>(initialSearch);
    const paymentsListApiParams = [
        ['created_after', createdAfter],
        ['created_before', createdBefore],
        ['limit', limit],
        ['offset', offset],
        ['search', searchQuery],
        ['sort_by', by],
        ['sort_dir', direction]
    ];
    const [listResponse, loading, getList] = usePaymentsApiFetch(
        `${paymentsListApiPath}?${paymentsListApiParams
            .concat(filterParams)
            .map((queryStringValue) => (queryStringValue[1] ? queryStringValue.join('=') : ''))
            .filter((queryStringParam) => queryStringParam)
            .join('&')}`
    );
    const { data: list } = listResponse || {};
    const [loadingResolve, setLoadingResolve] = useState<() => void>(() => () => {});
    const [pageCount, setPageCount] = useState<number>(0);
    const setCreatedDateRange = useCallback(([createdAfter, createdBefore] = []) => {
        setCreatedAfter(createdAfter ? convertDateToISOStringWithoutMs(createdAfter) : '');
        setCreatedBefore(
            createdBefore ? convertDateToISOStringWithoutMs(getDateAtEndOfDay(createdBefore)) : ''
        );

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setLoadingResolve(() => resolve);
            });
        });
    }, []);
    const setFilter = useCallback((filterParams) => {
        setFilterParams(filterParams);

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setLoadingResolve(() => resolve);
            });
        });
    }, []);
    const setPageIndex = useCallback(
        (pageIndex) => {
            const pageOffset = limit * pageIndex;

            setPagination({ limit, offset: pageOffset });

            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    setLoadingResolve(() => resolve);
                });
            });
        },
        [limit]
    );
    const setSearch = useCallback(
        (search) => {
            setPageIndex(0);
            setSearchQuery(search);

            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    setLoadingResolve(() => resolve);
                });
            });
        },
        [setPageIndex]
    );
    const setSort = useCallback((sort) => {
        setSortData(sort);

        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setLoadingResolve(() => resolve);
            });
        });
    }, []);

    useEffect(() => {
        if (!loading) {
            loadingResolve();
            setIsZeroState(listResponse?.meta?.totalCount === 0);
            setPageCount(Math.ceil(listResponse?.meta?.count / limit));
        }
    }, [limit, listResponse, loading, loadingResolve]);

    return (
        <Component
            getList={getList}
            initialCreatedDateRange={initialCreatedDateRange}
            initialSearch={initialSearch}
            isZeroState={isZeroState}
            list={list}
            loading={loading}
            setCreatedDateRange={setCreatedDateRange}
            setFilter={setFilter}
            setSearch={setSearch}
            setPageIndex={setPageIndex}
            setSort={setSort}
            pageCount={pageCount}
        />
    );
};

export default withPaymentsApiList;
