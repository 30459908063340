import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Flex, H3, LoadingDialog, P, styled } from 'sp-ui';
import { ReactComponent as AddBankImage } from '../../assets/svg/add-bank.svg';
import { MerchantAccountFormSubmitButton } from '../../components/merchant-account';
import {
    OnboardingForm,
    OnboardingFormContent,
    OnboardingPageFooter,
    Step
} from '../../components/onboarding';
import {
    BankAccount,
    IBankAccount,
    IPayoutAccount,
    usePayoutAccount
} from '../../components/payout-account';
import { Message, useMessage } from '../../components/intl';

export const AddBankBox = styled(Flex, {
    shouldForwardProp: (prop) => prop !== 'isHidden'
})(
    ({ isHidden, theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
        padding: 14px 20px 13px 10px;

        ${isHidden ? 'display: none;' : ''}

        div {
            flex: 1;
        }

        p {
            line-height: 24px;
        }

        svg {
            flex: 0 0 142px;
            height: 133px;
            margin-right: 12px;
        }

        ${theme.responsive.smallScreenOnly`
            align-items: center;
            flex-direction: column;
            padding: 10px 16px 16px;

            [type="submit"] {
                width: 100%;
            }

            div {
                text-align: center;
            }

            p {
                margin-top: 0;
            }

            svg {
                margin-bottom: 12px;
                margin-right: 0;
            }
        `}
    `
);

interface IGetBankAccountProps {
    withBankAccount: (bankAccount: IPayoutAccount | null) => void;
}

export const GetBankAccount: React.FC<IGetBankAccountProps> = ({ withBankAccount }) => {
    const { payoutAccounts } = usePayoutAccount();

    useEffect(() => {
        const [bankAccount = null] = payoutAccounts.filter(({ type }) => type === 'bank_account');

        withBankAccount(bankAccount);
    }, [payoutAccounts, withBankAccount]);

    return null;
};

interface IPayoutAccountBankAccountFormSubmitButtonProps {
    bankAccountAdded: boolean;
}

export const PayoutAccountBankAccountFormSubmitButton: React.FC<IPayoutAccountBankAccountFormSubmitButtonProps> = ({
    bankAccountAdded
}) => {
    const { id } = useParams<{ id: string }>();
    const onboardingSettingsEditPath = `/payment-account/${id}/onboarding/settings/edit`;
    const { push } = useHistory();

    return (
        <MerchantAccountFormSubmitButton
            disabled={!bankAccountAdded}
            onClick={() => {
                push(onboardingSettingsEditPath);
            }}
        />
    );
};

const OnboardingPayoutAccountNewBankAccountPage: React.FC = () => {
    const [bankAccount, setBankAccount] = useState<IBankAccount | null>(null);
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const isPageLoading = showLoading;
    const t = useMessage();
    const setBankAccountFromBankAccount = useCallback(
        (existingBankAccount: IPayoutAccount | null) => {
            setShowLoading(false);

            if (!existingBankAccount) {
                return;
            }

            const { accountSubtype, bankName, last4 } = existingBankAccount;
            const getDisplayAccountSubtype = (accountSubtype) => {
                if (accountSubtype === 'checking') {
                    return t('payoutAccount.accountSubtype.checking');
                } else if (accountSubtype === 'savings') {
                    return t('payoutAccount.accountSubtype.savings');
                }

                return '';
            };
            const accountName = bankName || getDisplayAccountSubtype(accountSubtype);
            const bankAccount = {
                accountNumberMask: last4,
                accountName,
                verificationStatus: null
            };

            setBankAccount(bankAccount);
        },
        [t]
    );

    return (
        <>
            <OnboardingForm initialValues={{}}>
                <OnboardingFormContent currentStep={Step.Third}>
                    <H3>
                        <Message id="onboarding.payoutAccountNewBankAccount.heading" />
                    </H3>
                    <P>
                        <Message id="onboarding.payoutAccountNewBankAccount.description" />
                    </P>
                    {isPageLoading ? (
                        <LoadingDialog description="" />
                    ) : (
                        <>{bankAccount && <BankAccount bankAccount={bankAccount} />}</>
                    )}
                    {!bankAccount && (
                        <AddBankBox isHidden={isPageLoading}>
                            <AddBankImage />
                            <Box>
                                <P marginBottom="10px" marginTop="8px">
                                    <Message id="onboarding.payoutAccountNewBankAccount.addDescription" />
                                </P>
                            </Box>
                        </AddBankBox>
                    )}
                </OnboardingFormContent>
                <OnboardingPageFooter hasClose>
                    <PayoutAccountBankAccountFormSubmitButton
                        bankAccountAdded={Boolean(bankAccount)}
                    />
                </OnboardingPageFooter>
            </OnboardingForm>
            <GetBankAccount withBankAccount={setBankAccountFromBankAccount} />
        </>
    );
};

export default OnboardingPayoutAccountNewBankAccountPage;
