import { Image } from '@chakra-ui/react';
import React from 'react';
import { Box, BoxProps, Flex, Span, StatusPill, styled } from 'sp-ui';
import {
    BankAccountImage,
    IPayoutAccount,
    PayoutAccount,
    usePayoutAccount
} from '../payout-account';
import Menu, { IMenuItem } from '../Menu';
import { useMessage } from '../intl';
import { usePaymentsApiFetch, usePaymentsApiMerchantAccountPath } from '../../hooks';
import { useToast } from '../toast';

export const BorderedBox = styled(Box)(
    ({ theme }) => `
        border: 1px solid ${theme.colors.gray[200]};
        border-radius: 4px;
    `
);

interface IBorderedPayoutAccountDetailsProps {
    payoutAccount: IPayoutAccount;
}

export const BorderedPayoutAccountDetails: React.FC<
    IBorderedPayoutAccountDetailsProps & BoxProps
> = ({ payoutAccount, ...props }) => (
    <BorderedBox {...props}>
        <PayoutAccountDetails hideStatusPill payoutAccount={payoutAccount} showMenu={false} />
    </BorderedBox>
);

interface IPayoutAccountDetailsProps {
    hideStatusPill?: boolean;
    onRemoveDefaultPayoutAccount?: () => void;
    onRemovePayoutAccount?: (payoutAccountId: string) => void;
    payoutAccount: IPayoutAccount;
    showMenu: boolean;
    allowSetDefault?: boolean;
}

const PayoutAccountDetails: React.FC<IPayoutAccountDetailsProps & BoxProps> = ({
    hideStatusPill,
    onRemoveDefaultPayoutAccount = () => {},
    onRemovePayoutAccount = () => {},
    payoutAccount,
    showMenu,
    allowSetDefault = true,
    ...props
}) => {
    const { cardBrand, id, isDefaultForCurrency, type } = payoutAccount;
    const isBankAccount = type === 'bank_account';
    const isCardAccount = type === 'card_account';
    const t = useMessage();

    return (
        <PayoutAccountDetailsContainer
            data-testid="payout-account-details"
            paddingBottom="16px"
            paddingLeft="20px"
            paddingRight="20px"
            paddingTop="16px"
            {...props}>
            {isBankAccount && <BankAccountImage marginRight="20px" width="40px" />}
            {isCardAccount && (
                <Image marginRight="20px" src={`/img/card/${cardBrand}.png`} width="40px" />
            )}
            <PayoutAccountDetailsContent>
                <Span>
                    <PayoutAccount multiline payoutAccount={payoutAccount} />
                </Span>
                {!hideStatusPill && isDefaultForCurrency && (
                    <StatusPill
                        statusPillText={t('settings.payoutSettings.defaultPayoutAccountStatusPill')}
                    />
                )}
            </PayoutAccountDetailsContent>
            {showMenu && (
                <PayoutAccountDetailsMenu
                    allowSetDefault={allowSetDefault}
                    isDefaultForCurrency={Boolean(isDefaultForCurrency)}
                    onRemoveDefaultPayoutAccount={onRemoveDefaultPayoutAccount}
                    onRemovePayoutAccount={onRemovePayoutAccount}
                    payoutAccountId={id}
                />
            )}
        </PayoutAccountDetailsContainer>
    );
};

export const PayoutAccountDetailsContainer = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        justify-content: space-between;

        &:not(:last-of-type) {
            border-bottom: 1px solid ${theme.colors.gray[200]};
        }

        ${theme.responsive.smallScreenOnly`
            align-items: flex-start;
        `}
    `
);

const PayoutAccountDetailsContent = styled(Flex)(
    ({ theme }) => `
        align-items: center;
        flex: 1;
        justify-content: space-between;

        ${theme.responsive.smallScreenOnly`
            align-items: flex-start;
            flex-direction: column;

            span {
                margin-bottom: 5px;
            }

            + div {
                margin-top: 12px;
            }
        `}
    `
);

interface IPayoutAccountDetailsMenuProps {
    isDefaultForCurrency: boolean;
    payoutAccountId: string;
    onRemoveDefaultPayoutAccount: () => void;
    onRemovePayoutAccount: (payoutAccountId: string) => void;
    allowSetDefault?: boolean;
}

export const PayoutAccountDetailsMenu: React.FC<IPayoutAccountDetailsMenuProps> = ({
    isDefaultForCurrency,
    payoutAccountId,
    onRemoveDefaultPayoutAccount,
    onRemovePayoutAccount,
    allowSetDefault = true
}) => {
    const { createToast } = useToast();
    const { getPayoutAccounts } = usePayoutAccount();
    const t = useMessage();
    const menuItems: IMenuItem[] = [
        {
            isDestructive: true,
            onClick: async () => {
                if (isDefaultForCurrency) {
                    onRemoveDefaultPayoutAccount();
                } else {
                    onRemovePayoutAccount(payoutAccountId);
                }
            },
            text: 'settings.payoutSettings.removePayoutAccountMenuItem'
        }
    ];
    const patchPayoutAccountPath = usePaymentsApiMerchantAccountPath(
        `/payout-account/${payoutAccountId}`
    );
    const { 2: patchPayoutAccount } = usePaymentsApiFetch(patchPayoutAccountPath, {
        data: {
            isDefaultForCurrency: true
        },
        defer: true,
        method: 'patch'
    });

    if (!isDefaultForCurrency && allowSetDefault) {
        menuItems.unshift({
            onClick: async () => {
                await patchPayoutAccount();
                await getPayoutAccounts();
                createToast({
                    text: t('settings.payoutSettings.payoutAccountUpdatedToast.text'),
                    subText: t('settings.payoutSettings.payoutAccountUpdatedToast.subText')
                });
            },
            text: 'settings.payoutSettings.updateDefaultPayoutAccountMenuItem'
        });
    }

    return <Menu items={menuItems} marginLeft="8px" />;
};

export default PayoutAccountDetails;
